import {useForm} from "react-hook-form";
import CardTable from "../components/card/CardTable";
import {ColumnInterface} from "../components/table/typings";
import {useQueries} from "../hooks/useQueries";
import {APP_API_URL} from "../config/api";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
import moment from "moment";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {useRef} from "react";
import {Select2} from "../components/form/Select";
import SimpleButton from "../components/button/SimpleButton";
import {BiSearchAlt2} from "react-icons/bi";
import {InputDate} from "../components/form/Input";

export default function ClientPage() {
    /**
     * Hooks
     * */
    const {baseApiUrl, title, pagination, setPagination} =
        useHeaderFunctionPage({baseApiUrl: "client", title: "Clients"});
    const town = useRef(null);
    const gender = useRef(null);
    const categoryOne = useRef(null);
    const categoryTwo = useRef(null);
    const answer = useRef(null);
    const fromDate = useRef(moment().subtract(7, "day").format("YYYY-MM-DD"));
    const toDate = useRef(moment().format("YYYY-MM-DD"));
    const queryKey = [
        `${baseApiUrl}s`,
        pagination.page,
        pagination.perPage,
        town.current,
        gender.current,
        answer.current,
        categoryOne.current,
        categoryTwo.current,
        fromDate.current,
        toDate.current,
    ];

    /**
     * Form
     */
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm();

    /**
     * Query
     */
    const {data: datas, isLoading} = useQueries(
        APP_API_URL +
        `${baseApiUrl}s?page=${pagination.page}&perPage=${pagination.perPage}${town.current ? `&town=${town.current}` : ""}${
            gender.current ? `&gender=${gender.current}` : ""
        }${
            answer.current ? `&answer=${answer.current}` : ""
        }${fromDate.current ? `&fromDate=${fromDate.current}` : ""}${
            toDate.current ? `&toDate=${toDate.current}` : ""
        }${categoryOne.current ? `&categoryOne=${categoryOne.current}` : ""}
        ${categoryTwo.current ? `&categoryTwo=${categoryTwo.current}` : ""}`,
        queryKey
    );


    /**
     * Function
     */
    const handleSubmitFilterForm = (values: any) => {
        town.current = values.town?.name;
        gender.current = values.gender?.id;
        answer.current = values.answer?.name;
        fromDate.current = values.startDate;
        toDate.current = values.endDate;
        categoryTwo.current = values.categoryTwo?.name;
        categoryOne.current = values.categoryOne?.name;
    };

    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Noms",
            key: "name",
            dataIndex: "name",
        },
        {
            title: "Ville",
            key: "town",
            dataIndex: "town",
            /*render: (element: any) => (
                <>{ element.town === 1 ? "YAOUNDÉ" : "DOUALA" }</>
            ),*/
        },
        {
            title: "Catégorie meilleur humouriste",
            key: "category_one",
            dataIndex: "category_one",
            /*render: (element: any) => (
                <>{element.category_one === 1 ? "Moustik" : "Takam"}</>
            ),*/
        },
        {
            title: "Catégorie artiste féminin",
            key: "category_two",
            dataIndex: "category_two",
            /*render: (element: any) => (
                <>{element.category_two === 1 ? "Sandrine Nanga" : "Alexandra Soppo"}</>
            ),*/
        },
        {
            title: "Numéro",
            key: "phone",
            dataIndex: "phone",
        },
        /*{
            title: "Genre",
            key: "gender",
            dataIndex: "gender",
            render: (element: any) => (
                <>{element.gender === 1 ? "Féminin" : "Masculin"}</>
            ),
        },*/
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
            ),
        },

    ];

    /**
     * UI
     */

    return (
        <>
            <Card
                title={"Filtres"}
                extras={[]}
            >
                <Form
                    loading={isLoading}
                    onSubmit={handleSubmit(handleSubmitFilterForm)}
                >
                    <div className={"grid lg:grid-cols-4 md:grid-cols-2 gap-2"}>
                        <div>
                            <InputDate
                                label={"Date de début"}
                                register={register}
                                error={errors?.startDate}
                                name={"startDate"}
                                defaultValue={moment().subtract(7, "day").format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <InputDate
                                label={"Date de fin"}
                                register={register}
                                error={errors?.endDate}
                                name={"endDate"}
                                defaultValue={moment().format("YYYY-MM-DD")}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la ville"
                                control={control}
                                name={"town"}
                                error={errors?.town}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={[
                                    {id: 1, name: "DOUALA"},
                                    {id: 2, name: "YAOUNDÉ"},
                                ]}
                                label="Ville"
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la catégorie meilleur humouriste"
                                control={control}
                                name={"categoryOne"}
                                error={errors?.categoryOne}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={[
                                    {id: 1, name: "Moustik"},
                                    {id: 2, name: "Takam"},
                                ]}
                                label="meilleur humouriste"
                                required={false}
                            />
                        </div>
                        <div>
                            <Select2
                                placeholder="Selectionner la Catégorie meilleur artiste féminin"
                                control={control}
                                name={"categoryTwo"}
                                error={errors?.categoryTwo}
                                register={register}
                                textKeyItem={"name"}
                                valueKeyItem={"id"}
                                items={[
                                    {id: 1, name: "Alexandra Soppo"},
                                    {id: 2, name: "Sandrine Nanga"},
                                ]}
                                label="meilleur artiste féminin"
                                required={false}
                            />
                        </div>
                        <div className={"flex items-end gap-2"}>
                            <SimpleButton
                                type={"submit"}
                                className="bg-soft-primary bg-opacity-40 hover:bg-opacity-100 rounded flex items-center justify-center mt-2     "
                            >
                                <BiSearchAlt2 className={"mr-2"}/> Filtrer
                            </SimpleButton>
                        </div>
                    </div>
                </Form>
            </Card>
            <CardTable
                extras={[]}
                columns={columns}
                title={title}
                loading={isLoading}
                datas={datas?.data}
                pagination={pagination}
                setPagination={setPagination}
                tableClassName="text-center"
            />
        </>
    )
        ;
}
