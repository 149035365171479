import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import Card from "../components/card/Card";
import {Form} from "../components/form/Form";
import {SubmitButton} from "../components/button/CustomButton";
import {HTTPStatusResponse} from "../config/global";
import {APP_API_URL} from "../config/api";
import {useMutate} from "../hooks/useMutations";
import "../assets/styles/tombola.css";
import {ColumnInterface} from "../components/table/typings";
import moment from "moment";
import Table from "../components/table/Table";
import {useState, useEffect} from "react";

export default function BigTombolaPage() {
    /**
     * Hooks
     */
    const [results, setResults] = useState([]);
    const [isSend, setIsSend] = useState(false);
    const [animateBall, setAnimateBall] = useState(false);

    /**
     * Form
     */
    const {
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm();

    /**
     * Mutation
     */
    const {mutateAsync, isLoading} = useMutate(
        APP_API_URL + `tombola`,
        "post"
    );

    /**
     * Function
     */
    const handleSubmitForm = (values: any) => {
        setResults([]);
        setAnimateBall(true);
        mutateAsync({
            ...values
        }).then((response: any) => {
            if (response && response.data.status === HTTPStatusResponse.OK) {
                toast.success("Tirage éffectué !");
                //setConfettiVisible(true);
                setTimeout(() => {
                    // setConfettiVisible(false);
                }, 5000);
                setResults(response.data.data ?? []);
            } else {
                toast.error(response?.data.message);
            }
            setAnimateBall(false);
        });
    };

    /**
     * Columns Table
     */
    const columns: ColumnInterface[] = [
        {
            title: "Ville",
            key: "town",
            dataIndex: "town",
            render: (element: any) => (
                <>{element.town === 1 ? "YAOUNDÉ" : "DOUALA"}</>
            ),
        },
        {
            title: "Numéro de téléphone",
            key: "phone",
            dataIndex: "phone",
        },
        {
            title: "Date",
            key: "created_at",
            render: (element: any) => (
                <>{moment(element.created_at).format("YYYY-MM-DD HH:mm:ss")}</>
            ),
        },
    ];

    /**
     * UI
     */
    return (
        <div className="relative">
            <>
                <div className="grid md:grid-cols-2 gap-4">
                    <Card title={"Tombola"}>
                        <div
                            className="mx-auto px-4 border border-gray-200 rounded-full w-[400px] h-[400px] border-b-[100px] border-t-[#fff] border-t-[25px] relative flex items-center">
                            <div
                                className={`rounded-full h-[400px] p-10 relative w-full ${
                                    animateBall ? "animate" : ""
                                }`}
                            >
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value: number) => (
                                    <span key={value} className="loader-tombola">
                      <span>{value}</span>
                    </span>
                                ))}
                            </div>
                        </div>
                    </Card>
                    <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
                        <Card
                            title="Lancer la  Tombola"
                            extras={[
                                <SubmitButton
                                    value="Démarrer"
                                    loading={isLoading}
                                    className="disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-white"
                                    key="submit"
                                />

                            ]}
                        >
                            <div className={"mb-5 grid grid-cols-2 gap-4"}>
                            </div>
                            <Table
                                tableClassName="text-left"
                                columns={columns}
                                loading={false}
                                datas={results}
                            />
                        </Card>
                    </Form>
                </div>
            </>

        </div>
    );
}
